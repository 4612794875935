<template>
  <b-card>
    <b-card-title>Création d'un device</b-card-title>
    <b-form
      @submit.prevent
      @submit="createDevice(deviceData)"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Nom"
            label-for="h-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-name"
              v-model="deviceData.name"
              type="text"
              placeholder="Nom"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Type de device"
            label-for="h-type"
            label-cols-md="4"
          >
            <b-form-select
              v-model="deviceData.deviceType_id"
              name="h-type"
              :options="deviceTypeOptions"
              text-field="name"
              value-field="id"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Utilisateur"
            label-cols-md="4"
          >
            <b-form-select
              v-model="deviceData.user_id"
              name="h-user"
              :options="userOptions"
              text-field="fullname"
              value-field="id"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Entreprise"
            label-for="h-enterprise"
            label-cols-md="4"
          >
            <b-form-select
              v-model="deviceData.enterprise"
              name="h-enterprise"
              :options="enterpriseOptions.filter(onlyUnique).sort()"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Tête associée"
            label-for="h-headAssociate"
            label-cols-md="4"
          >
            <b-form-input
              v-model="deviceData.headAssociate"
              name="h-headAssociate"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Valise associée"
            label-for="h-suitcaseAssociate"
            label-cols-md="4"
          >
            <b-form-input
              v-model="deviceData.suitcaseAssociate"
              name="h-suitcaseAssociate"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="deviceData.is_active"
              checked="false"
              name="h-active"
              switch
              inline
            >
              Actif
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="deviceData.cofrac"
              checked="false"
              name="h-cofrac"
              switch
              inline
            >
              Cofrac
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="deviceData.rent"
              checked="false"
              name="h-rent"
              switch
              inline
            >
              Location
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Dernier étalonnage"
            label-for="h-lastMetrology"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="h-lastMetrology"
              v-model="deviceData.lastMetrology"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="Dernier étalonnage"
              hide-header
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Prochain étalonnage"
            label-for="h-nextMetrology"
            label-cols-md="4"
          >
            <b-form-datepicker
              id="h-nextMetrology"
              v-model="deviceData.nextMetrology"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="Prochain étalonnage"
              hide-header
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Sauvegarder
          </b-button>
          <b-link
            :to="{ name: 'devices' }"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-primary"
            >
              Annuler
            </b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
  BCard, BCardTitle, BFormSelect, BLink, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardTitle,
    BFormSelect,
    BLink,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      deviceData: {},
      deviceTypeOptions: this.$store.state.devicetype.devicetypesList,
      enterpriseOptions: [],
      userOptions: [],
    }
  },
  mounted() {
    if (this.$store.state.user.usersList) {
      this.userOptions = this.$store.state.user.usersList.sort((a, b) => a.username.localeCompare(b.username))
      this.userOptions.forEach(user => {
        user.fullname = `${user.firstname} ${user.lastname}`
        this.enterpriseOptions.push(user.enterprise)
      })
    }
  },
  methods: {
    async createDevice(deviceData) {
      const newDevice = deviceData
      // newDevice.enterprise = this.$store.state.user.usersList.filter(user => user.id === newDevice.user_id)[0].enterprise
      newDevice.id = (Math.floor(Math.random() * 0xFFFFFFFFFFFFFFFF)).toString(16)
      await this.$store.dispatch('device/createDevice', newDevice)
        .then(response => this.$router.push({ name: 'devices-view', params: { id: response.data.id } }))
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
  },
}
</script>
